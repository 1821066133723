import { useState, useEffect, useRef, SetStateAction } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Essentials,
  FindAndReplace,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  Paragraph,
  RemoveFormat,
  SelectAll,
  Underline,
  Undo,
  WordCount,
  Plugin,
  EditorConfig,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

import "./StoryEditor.css";

const CHAR_LIMIT_KB = 98;
const CHAR_LIMIT = CHAR_LIMIT_KB * 1024;

interface StoryEditorProps {
  data: string;
  onChange: (event: any, editor: any) => void;
  placeholder?: string;
  characterLimit?: number;
}

export default function StoryEditor({
  data,
  onChange,
  placeholder = "Your story here!",
  characterLimit = CHAR_LIMIT,
}: StoryEditorProps) {
  class CharacterLimitPlugin extends Plugin {
    init() {
      const editor = this.editor;
      const limit = characterLimit;
      let lastData = "";

      editor.plugins.get("WordCount").on("update", (evt, stats) => {
        if (stats.characters >= limit) {
          alert(
            `You have reached the maximum allowed characters (${Math.ceil(
              limit / 1024
            )}kb).`
          );
          editor.setData(lastData);
        }
        lastData = editor.getData();
        setCharCount(lastData.length);
      });
    }
  }

  const editorConfig: EditorConfig = {
    licenseKey: "GPL",
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "findAndReplace",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "removeFormat",
        "|",
        "link",
        "blockQuote",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Essentials,
      FindAndReplace,
      Heading,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      Paragraph,
      RemoveFormat,
      SelectAll,
      Underline,
      Undo,
      WordCount,
      CharacterLimitPlugin,
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ] as const,
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    wordCount: {
      onUpdate: (stats: { words: SetStateAction<number> }) => {
        setWordCount(stats.words);
        // setCharCount(stats.characters);
      },
    },
    placeholder: placeholder,
  };

  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  return (
    <div
      className="editor-container editor-container_classic-editor"
      ref={editorContainerRef}
    >
      <div className="editor-container__editor">
        <div>
          {isLayoutReady && (
            <CKEditor
              ref={editorRef}
              editor={ClassicEditor}
              data={data}
              config={editorConfig}
              onChange={onChange}
            />
          )}
        </div>
      </div>
      <div className="word-count">
        Words: {wordCount} ({Math.ceil(charCount / 1024)} of{" "}
        {Math.ceil(characterLimit / 1024)}kb)
      </div>
    </div>
  );
}
