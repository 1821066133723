import React from "react";
import "./Loader.css";

interface LoaderProps {
  showOverlay?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ showOverlay = true }) => {
  return (
    <div className={showOverlay ? "loader-overlay" : ""}>
      <div className="loader">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </div>
  );
};

export default Loader;
